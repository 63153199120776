<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-1">
                <h3 class="mb-0">{{ task.status >= "10" ? "タスク" : "変更" }}：{{ task.name }}</h3>
              </b-card-header>
              <b-card-body>
                <!--一時保存中または差戻した-->
                <b-form v-if="task.status < '10'" @submit.prevent>
                  <h6 class="heading-small text-muted mb-4">所属案件</h6>
                  <div class="pl-lg-4">
                    <b-row v-if="task.workflow === '10'">
                      <b-col md="6" lg="6">
                        <base-select
                          label="所属案件"
                          v-model="task.project_id" 
                          :options="project_choice"
                        >
                        </base-select>
                      </b-col>
                      <b-col md="6" lg="6">
                        <base-select
                          label="所属部署"
                          v-model="task.org_id"
                          :options="org_choice"
                        >
                        </base-select>
                      </b-col>
                    </b-row>
                    <b-row v-else-if="task.workflow === '11'">
                      <ExpenseWidget
                        :initial="task"
                        :org_choice="org_choice"
                        :project_choice="project_choice"
                        @input="updateByWidget"
                      />
                    </b-row>
                    <b-row v-else-if="task.workflow === '12'">
                      <!-- 勤怠登録 -->
                      <b-col md="6" lg="6">
                        <label class="form-control-label">所属部署</label>
                        <div class="pl-lg-4">{{ full_org_name}}</div>
                      </b-col>
                    </b-row>
                  </div>
                  <template v-if="task.workflow === '20'">
                    <!-- 有休申請 -->
                    <hr class="my-4">
                    <h6 class="heading-small text-muted mb-4">現在の有休情報</h6>
                    <div class="pl-lg-4">
                      <paid-vacation-widget />
                    </div>
                  </template>
                  <template v-if="task.status === '02' && undo_info">
                    <hr class="my-4">
                    <h6 class="heading-small text-muted mb-4">差戻し</h6>
                    <b-table-simple>
                      <b-tbody>
                        <b-tr>
                          <b-th class="text-right">承認者</b-th>
                          <b-td>{{undo_info.approver}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th class="text-right">差戻し理由</b-th>
                          <b-td>{{undo_info.reason}}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </template>
                  <hr class="my-4">
                  <h6 class="heading-small text-muted mb-4">タスク基本情報</h6>
                  <!--勤怠申請-->
                  <div v-if="task.workflow === '12'" class="pl-lg-4">
                    <attendance-form
                            :schema="fields_schema"
                            :layout="fields_layout"
                            :value="fields"
                            ref="basci_info_form"
                    />
                  </div>
                  <!--資材申請-->
                  <div v-else-if="task.workflow === '13' || task.workflow === '14'" class="pl-lg-4">
                    <assets-form
                            :schema="fields_schema"
                            :layout="fields_layout"
                            :value="fields"
                            :workflow_code="task.workflow"
                            @inventory="handleInventory"
                            ref="basci_info_form"
                    />
                  </div>
                  <div v-else class="pl-lg-4">
                    <base-form
                      :schema="fields_schema"
                      :layout="fields_layout"
                      :value="fields"
                      @input="handleChange"
                      ref="basci_info_form"
                    />
                  </div>
                </b-form>
                <!--申請済-->
                <div v-else>
                  <h6 class="heading-small text-muted mb-4">タスク内容</h6>
                  <template v-if="task.workflow === '12'">
                    <AttendanceViewFields :task="task" :fields="attendance_fields" :fields_value="attendance_fields_value" />
                  </template>
                  <template v-else-if="task.workflow === '13' || task.workflow === '14'">
                    <AssetsViewFields :task="task" :fields="fields" />
                  </template>
                  <template v-else>
                    <ViewFields :task="task" :fields="fields" />
                  </template>
                  <hr class="my-4">
                  <h6 class="heading-small text-muted mb-4">処理状況</h6>
                  <flow-chart :nodes="nodes">
                  </flow-chart>
                </div>
              </b-card-body>
              <b-card-footer>
                <b-row>
                  <b-col cols="12" class="d-flex justify-content-between">
                    <span v-if="task.status < '10'">
                      <b-button variant="light" @click="updateTask(false)">一時保存</b-button>
                      <b-button variant="primary" @click="updateTask(true)">申請</b-button>
                    </span>
                    <span v-else></span>
                    <span>
                      <b-button v-if="task.status < '10'" variant="danger" @click="deleteTask">削除</b-button>
                      <b-button variant="dark" :to="{name: 'tasks-unresolved'}">戻る</b-button>
                    </span>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>

      <b-modal
          id="working_time_confirm_modal"
          scrollable
          noCloseOnBackdrop
          size="md"
          @ok="handleWorkingTimeConfirm"
      >
          <template v-slot:modal-title>
              <b-icon-exclamation-triangle-fill class="mr-2" variant="warning" font-scale="2.5"></b-icon-exclamation-triangle-fill>
              勤怠登録
          </template>
          <p class="my-4">
              {{ working_time_confirm_message }}
          </p>
      </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { ajax, common, config, constant } from '../../utils';
import Toast from '../../components/Toast';
import ExpenseWidget from './widgets/expense';
import PaidVacationWidget from './widgets/paid_vacation.vue';
import {ViewFields} from '../common';
import { helper } from '../common';
import { AttendanceViewFields } from '../common';
import { AssetsViewFields } from '../common';

export default {
  components: {
    ViewFields,
    ExpenseWidget,
    PaidVacationWidget,
    AttendanceViewFields,
    AssetsViewFields,
  },
  data() {
    return {
      loading: true,
      task: {},
      nodes: [],
      fields_schema: [],
      fields_layout: [],
      fields: {},
      undo_info: null,
      attendance_fields: [],
      attendance_fields_value: {},
      is_assets_inventory: true,
      working_time_confirm_message: null,
    };
  },
  watch: {
    $route(to, from) {  // eslint-disable-line
      this.getTaskDetail(this.task_id);
    },
    organizationsMap(newValue, oldValue) {
      console.log('oldValue', oldValue)
      if(newValue) {
        let org_full_name = newValue.get(this.task.org_id)
        this.task = {...this.task, org_full_name,}
      }     
    }
  },
  created: function () {
    this.getTaskDetail(this.task_id);
  },
  updated: function() {
    document.title = `${this.task.name} | ${common.getPageName(this.$route.name)}`;
  },
  computed: {
    ...mapState('account', ['user', 'organizations', 'organizationsMap']),
    ...mapGetters('account', ['full_org_name']),
    task_id: function() {
      return this.$route.params.id;
    },
    project_choice: function() {
      if (this.user && this.user.projects) {
        return this.user.projects.map(p => ({ value: p.project_id, text: p.project_name }));
      } else {
        return [];
      }
    },
    org_choice: function() {
      if (this.organizations) {
        return this.organizations.map(o => ({ value: o.id, text: o.name, parent: o.parent_id }));
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions('account', ['getStatistics']),
    getTaskDetail: function(id) {
      const vm = this;
      ajax.fetchGet(common.formatStr(config.api.task.task_detail, id)).then(task => {
        vm.task = task;
        return task;
      })
      .then(async (task) => {
        const _data = await ajax.fetchGet(common.formatStr(config.api.employee.v2_employee_detail, vm.task.employee)).catch(err => console.log(err)) || {};
        let full_org_name ="";
        if(this.organizationsMap.get) {
          full_org_name = this.organizationsMap.get(vm.task.org_id)
        }
        vm.task = {...vm.task , employee_code : _data.code || "退職済み", org_full_name: full_org_name}
        return task;
      })
      .then(async task => {
        const fields = await ajax.fetchGet(common.formatStr(config.api.task.task_form, id));
                if(task.status >= '10') {
          vm.fields = fields;
          //勤怠申請
          if (task.workflow === '12') {
            vm.attendance_fields = fields;
            vm.attendance_fields_value = helper.get_value_from_fields(fields);
          }
        } else {
          vm.fields_schema = helper.get_schema_from_fields(fields);
          vm.fields_layout = helper.get_layout_from_fields(fields);
          vm.fields = helper.get_value_from_fields(fields);
        }
        return task;
      }).then(async task => {
        const nodes = await ajax.fetchGet(common.formatStr(config.api.task.task_nodes, id));
        vm.nodes = nodes;
        return task;
      }).then(async task => {
        if (task.status === '02') {
          // 差戻した場合、差戻し理由などを取得する
          await ajax.fetchGet(common.formatStr(config.api.task.task_undo_info, id)).then(data => {
            vm.undo_info = data;
          });
        }
      }).finally(() => {
        vm.loading = false;
      });
    },
    updateByWidget(data) {
      this.task.org_id = data.org_id;
      this.task.project_id = data.project_id;
    },
    updateTask: async function(submit) {
      const isValid = await this.$refs.basci_info_form.validate();
      let workflow_code = this.task.workflow;
      if (isValid) {
        if (submit === true) {
            let shouldConfirm = this.fields && this.fields['working_time_confirm'] !== true;
          if (shouldConfirm && !confirm(`タスク「${this.task.name}」をコミットします、よろしいですか？`)) {
            return;
          }
          //資材申請
          if (this.task.workflow === '13' && this.is_assets_inventory === false) {
            workflow_code = '14';
          }
          //勤怠申請

          // if (this.task.workflow === '12') {
          //
          //   const businessDays = this.fields.basic_attendance_day;
          //   const workTimes = businessDays * 8;
          //   const totalWorkTime = this.fields.amount_time_3;
          //     console.log(this.task.workflow, businessDays, workTimes, totalWorkTime)
          //   if (totalWorkTime < workTimes) {
          //       if (!confirm(`現在の勤務時間は${totalWorkTime}時間です。必要な総時間は${workTimes}時間ですので、不足分は${workTimes - totalWorkTime}時間です。コミットしてもよろしいですか？`)) {
          //           return;
          //       }
          //   }
          // }

        }
        const vm = this;
        common.loading();
        ajax.fetchPut(common.formatStr(config.api.task.task_detail, this.task_id), {
          workflow: workflow_code,
          employee: vm.task.employee,
          project_id: vm.task.project_id,
          project_name: vm.task.project_name,
          org_id: vm.task.org_id,
          org_name: vm.task.org_name,
          status: submit === true ? '10' : '01',
          fields: vm.fields,
        }).then(() => {
          Toast.success(constant.SUCCESS.SAVED);
          vm.getStatistics();
          vm.$router.push({ name: 'tasks-unresolved' });
        }).catch(errors => {
            if ('working_time_confirm' in errors) {
                vm.working_time_confirm_message = errors.message;
                this.$bvModal.show('working_time_confirm_modal');
            } else {
                Toast.errorFromApi(errors)
                this.fields['working_time_confirm'] = false
            }
        }).finally(() => {
          common.loaded();
        });
      }
    },
    deleteTask: function() {
      if (confirm(`タスク「${this.task.name}」を削除します、よろしいですか？`)) {
        const vm = this;
        common.loading();
        ajax.fetchDelete(common.formatStr(config.api.task.task_detail, this.task.id)).then(() => {
          Toast.success(constant.SUCCESS.DELETED);
          vm.getStatistics();
          vm.$router.push({ name: 'tasks-unresolved' });
        }).catch(errors => {
          Toast.errorFromApi(errors)
        }).finally(() => {
          common.loaded();
        });
      }
    },
    handleChange(val) {
      if (this.task.workflow === "20") {
        // 有休申請
        const { start_date, days, is_start_half } = val;
        if (start_date && days) {
          ajax.fetchGet(`${config.api.employee.paid_vacation_end_date}?start_date=${start_date}&days=${days}&is_start_half=${is_start_half}`).then(_data => {
            this.fields = Object.assign({}, val, { end_date: common.formatDate(_data.end_date, "YYYY-MM-DD") })
          }).catch(errors => {
            Toast.errorFromApi(errors);
            this.fields = Object.assign({}, val, { end_date: null })
          })
        }
      }
    },
    handleInventory(inventory) {
      //資材申請
      if (this.task.workflow === "13") {
        this.is_assets_inventory = inventory;
      }
    },
    handleWorkingTimeConfirm() {
      this.fields['working_time_confirm'] = true;
      this.updateTask(true);
      this.$bvModal.hide('working_time_confirm_modal');
    },
  },
}
</script>