<template>
  <b-col>
    <b-row>
      <b-col lg="12">
        <b-form-group>
          <b-form-radio-group
            v-model="selected"
            name="expense-type"
          >
            <b-form-radio value="0">組織経費</b-form-radio>
            <b-form-radio value="1">案件経費</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" v-if="selected==='0'">
        <base-select
          label="所属部署"
          v-model="model.org_id"
          :options="org_choice"
          @input="updateValue($event, 'org_id')"
        >
        </base-select>
      </b-col>
      <b-col lg="12" v-else-if="selected==='1'">
        <base-select
          label="所属案件"
          v-model="model.project_id" 
          :options="project_choice"
          @input="updateValue($event, 'project_id')"
        >
        </base-select>
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
export default {
  props: {
    initial: { type: Object, default: () => ({}) },
    org_choice: { type: Array, default: () => [] },
    project_choice: { type: Array, default: () => [] },
  },
  data() {
    return {
      selected: '0',
      model: {
        org_id: this.initial.org_id,
        project_id: this.initial.project_id,
      }
    }
  },
  updated: function() {
    if (this.initial.org_id && !this.model.org_id) {
      this.model.org_id = this.initial.org_id;
    }
  },
  methods: {
    updateValue: function(value, key) {
      const data = { org_id: this.org_id, project_id: this.project_id };
      data[key] = value;
      this.$emit('input', data)
    },
  },
}
</script>